<template>
  <content-wrapper>
    <h2 id="contact">Contact Us</h2>
    <Grid minmax="350px" class="grid">
      <div class="contact-content">
        <div class="contact-info">
          <p>Phone: (304)-788-9258</p>
          <p>PO Box 96</p>
          <p>New Creek, WV 26743</p>
          <p>Email:
            <a href="mailto:orders@narrowgateresources.com">
              orders@narrowgateresources.com
            </a>
          </p>
        </div>
        <contact-form />
      </div>
      <iframe src="https://www.google.com/maps?q=New+Creek+WV&output=embed" height="100%" width="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </Grid>
  </content-wrapper>
</template>

<script>
import ContactForm from '@/components/ContactForm';
import ContentWrapper from '@/components/ContentWrapper';
import Grid from '@/components/Grid';

export default {
  name: 'Contact',
  components: {
    ContactForm,
    ContentWrapper,
    Grid,
  },
  mounted() {
    if (this.$route.hash) {
      const id = this.$route.hash.substring(1);
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    }
  },
};
</script>

<style lang="scss" scoped>
.grid {
  gap: 12px !important;
  grid-auto-rows: 1fr;
  justify-items: flex-start;
}

a {
  color: var(--accent-color);
}

.contact-content {
  width: 100%;
  padding: 0 20px 0 20px;

  .contact-info {
    padding-bottom: 12px;
  }
}
</style>
